import { UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { ClientError, GraphQLClient } from 'graphql-request'
import { useCallback, useMemo } from 'react'

import { MutationErrorResponse } from '@unreserved-frontend-v2/api/mutations/mutation-error-response'

import { API_ENDPOINT } from '../constants'

// ------------------------------------------------------------------------------------------------------------
//
// NOTE:  Our hooks make use of useMemo() because they all return an object and if we return a new object
//        every time (even when the data doesn't change) any useEffects() that are dependent on this data
//        will re-run and potentially cause infinite render loops.
//
// ------------------------------------------------------------------------------------------------------------

export type UseMutateDataQueryType = <TData, TError>(
  client: GraphQLClient,
  options?: UseMutationOptions<TData, TError, TData>,
  headers?: RequestInit['headers']
) => UseMutationResult<TData, TError>

export const useMutateData = <TVariables, TData, TErrorType = ClientError>(
  useQueryMutation: UseMutateDataQueryType
) => {
  const { mutate, isSuccess, isError, data, error, reset, isLoading, mutateAsync } = useQueryMutation<
    TData,
    MutationErrorResponse<TErrorType>
  >(new GraphQLClient(API_ENDPOINT))

  const doMutation = useCallback(
    function (input: TVariables | { input: TVariables }) {
      mutate(input)
    },
    [mutate]
  )

  return useMemo(() => {
    return {
      mutate: doMutation,
      data,
      isError,
      isSuccess,
      isLoading,
      error,
      reset,
      mutateAsync,
    }
  }, [data, doMutation, error, isError, isLoading, isSuccess, reset, mutateAsync])
}
