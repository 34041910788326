import { Client } from '@twilio/conversations'
import { useEffect, useState } from 'react'

import { useMutateData } from '@unreserved-frontend-v2/api/base-hooks/use-mutate-data'
import {
  GenerateRealTimeNotificationTokenMutation,
  GenerateRealTimeNotificationTokenMutationVariables,
  useGenerateRealTimeNotificationTokenMutation,
} from '@unreserved-frontend-v2/api/generated/graphql/generate-real-time-notification-token.graphql'
import { UseCreateModifyMutationQueryType } from '@unreserved-frontend-v2/api/hooks/use-create-modify-mutation'

const BEFORE_ONE_HOUR = 59 * 60 * 1000

/**
 * Hook that instantiates twilio conversations client sdk using a token generated from our backend
 *
 * The twilio SDK event subscriptions are the mechanism that provide our clients realtime notifications from our back-end,
 * primarily for our 'threads' feature. The structure is so that back-end handles most of the twilio integration
 * while clients only use the sdk for event notifications. Clients only read and write data with mutations and queries from our back-end
 * api. Twilio client sdk is not to be used directly to mutate or read conversations data.
 *
 * docs: https://www.twilio.com/docs/conversations/sdk-overview
 */
export const useTwilio = (userReady: boolean) => {
  const [twilioClient, setTwilioClient] = useState<Client>()
  const [fetchingStarted, setFetchingStarted] = useState(false)

  const {
    mutate: generateToken,
    data,
    isSuccess,
  } = useMutateData<
    undefined,
    GenerateRealTimeNotificationTokenMutation,
    GenerateRealTimeNotificationTokenMutationVariables
  >(useGenerateRealTimeNotificationTokenMutation as UseCreateModifyMutationQueryType)

  useEffect(() => {
    if (userReady && !fetchingStarted) {
      generateToken(undefined)
      setInterval(() => {
        generateToken(undefined)
      }, BEFORE_ONE_HOUR)
      setFetchingStarted(true)
    }
  }, [userReady, generateToken, fetchingStarted])

  useEffect(() => {
    if (isSuccess) {
      if (!data?.generateRealTimeNotificationToken) {
        return
      }
      const client = new Client(data.generateRealTimeNotificationToken)

      setTwilioClient(client)
    }
  }, [isSuccess, data])

  return {
    twilioClient,
  }
}
