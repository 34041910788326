import { UserType } from '@unreserved-frontend-v2/api/generated/graphql/types'

export const TEAM_MEMBER_USER_TYPES = [UserType.Admin, UserType.Agent]
export const PERSON_USER_TYPES = [UserType.Consumer, UserType.Contact]

export const isTeamMember = (userType: UserType) => {
  return TEAM_MEMBER_USER_TYPES.includes(userType)
}

export const isPerson = (userType: UserType) => {
  return PERSON_USER_TYPES.includes(userType)
}
