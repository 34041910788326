import { GraphQLClient } from 'graphql-request'
import { RequestInit } from 'graphql-request/dist/types.dom'
import { parseCookies } from 'nookies'

import { HAVE_WINDOW } from '@unreserved-frontend-v2/utils/window'

export default function fetcher<TData, TVariables>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  headers?: RequestInit['headers']
) {
  return async (): Promise<TData> => {
    const { token } = parseCookies()

    const defaultHeaders = {
      'X-User-Agent': HAVE_WINDOW ? window.navigator.userAgent : '',
      ...(token ? { 'X-Auth-Token': token } : {}),
      'X-App-Version': process.env['NEXT_PUBLIC_APP_VERSION'] as string,
    }

    // This is awesome for debugging so leaving here commented out.
    //console.log(`-- fetcher:`, query.trimStart().substring(0, 35).replace('\n', ''), variables, token)
    //console.log(`-- fetcher:`, query.trimStart().substring(0, 35).replace('\n', ''))

    return client.request<TData, unknown>(query, variables, { ...defaultHeaders, ...headers })
  }
}
