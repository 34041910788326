import { useQueryClient } from '@tanstack/react-query'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import { destroyCookie, parseCookies, setCookie } from 'nookies'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { AuthenticatedUser, UserType } from '@unreserved-frontend-v2/api/generated/graphql/types'
import { useGetUser } from '@unreserved-frontend-v2/api/hooks/use-get-user'
import { useTwilio } from '@unreserved-frontend-v2/modules/users/components/user-info-provider/use-twilio'
import { TEAM_MEMBER_USER_TYPES } from '@unreserved-frontend-v2/modules/users/utils/userTypeHelpers'
import { getLocalItem, setLocalItem } from '@unreserved-frontend-v2/utils/local-storage/utils'

import { PreviewViewsType, UserContext, UserContextType } from './user-context'
import { User } from '../../types/user'

export enum CookieKeys {
  TOKEN = 'token',
  CONDENSED_SUMMARY = 'condensedSummary',
  PREVIEW_VIEW = 'previewView',
}

export type UserCookieValue = {
  [CookieKeys.CONDENSED_SUMMARY]: boolean
  [CookieKeys.TOKEN]: string
  [CookieKeys.PREVIEW_VIEW]?: PreviewViewsType
}

export interface UserInfoProviderProps {
  children: ReactNode
  serverToken?: string
  /** query keys to invalidate whenever a user changes */
  invalidateOnUserUpdate?: string[]
  /** View specific configurations */
  serverCondensedListingsSummary?: boolean
  serverCookies?: UserCookieValue
}
/**
 * 30 days in the browser for now
 */
const TOKEN_EXPIRY_TIME = 30 * 24 * 60 * 60

export function UserInfoProvider({ children, serverCookies, invalidateOnUserUpdate }: UserInfoProviderProps) {
  /** A unique ID used primarily for CREA purposes, tracked per unique browser */
  const [uuid, setUUID] = useState<string>()

  /** user is the full user object from the back-end */
  const [user, setUser] = useState<User>()

  /** Boolean to track if userData has been updated through any endpoint that can update userData */
  const [userReady, setUserReady] = useState(false)

  /** instantiated twilioClient to expose event subscribing */
  const { twilioClient } = useTwilio(userReady)
  twilioClient?.setMaxListeners(20)

  /** local value of cookies, from server or client default */
  const [userCookies, setUserCookies] = useState<UserCookieValue>(() => {
    const currentUserCookies: UserCookieValue = {
      [CookieKeys.CONDENSED_SUMMARY]: false,
      [CookieKeys.TOKEN]: '',
      [CookieKeys.PREVIEW_VIEW]: undefined,
    }

    // All cookies to be retrieved from the server
    Object.entries(currentUserCookies).forEach(([key, defaultValue]) => {
      const cookieKey = key as CookieKeys

      // Map each support value with what is saved in server cookies
      const serverCookieVal =
        serverCookies?.[cookieKey] ||
        (typeof defaultValue === 'boolean'
          ? !!parseCookies()?.[cookieKey] // Cast as a boolean if the default value expects it
          : parseCookies()?.[cookieKey]) ||
        defaultValue

      if (!isNil(serverCookieVal)) {
        ;(currentUserCookies[cookieKey] as unknown) = serverCookieVal
      }
    })
    return currentUserCookies
  })

  /** hook for getting User Data from the back-end */
  const userData = useGetUser({ retry: 0, enabled: !!userCookies.token })

  /** Boolean to track if a user has accepted the terms of use for CREA yet */
  const [CREATermsAccepted, setCREATermsAccepted] = useState<boolean>(true)
  const queryClient = useQueryClient()

  const updateUserCookieToken = useCallback(
    (token: string) => {
      setCookie(undefined, CookieKeys.TOKEN, token, {
        maxAge: TOKEN_EXPIRY_TIME,
        // We only use the domain for cookies in production so that sessions can be shared across consumer & admin
        ...(process.env['NEXT_PUBLIC_TOKEN_COOKIE_DOMAIN']
          ? { domain: process.env['NEXT_PUBLIC_TOKEN_COOKIE_DOMAIN'] }
          : {}),
        path: '/',
      })
      setUserCookies({
        ...userCookies,
        token: token,
      })
    },
    [userCookies]
  )

  /** Whenever we get an AuthenticatedUser back from the back-end, this method should be called to update the context state & cookies */
  const updateUser = useCallback(
    (authenticatedUser: AuthenticatedUser) => {
      // updateUser can potentially be called multiple times by consumers
      // the isEqual check is a safeguard against repeated unneeded state changes and query invalidations
      if (!userCookies.token || !isEqual(authenticatedUser.user, user)) {
        updateUserCookieToken(authenticatedUser.token)
        setUser(authenticatedUser.user)
        setUserReady(true)

        invalidateOnUserUpdate?.forEach((query) => queryClient.invalidateQueries([query]))
      }
    },
    [user, userCookies, invalidateOnUserUpdate, queryClient, updateUserCookieToken]
  )

  /** Completely removes the user. */
  const clearUser = useCallback(() => {
    if (!!userCookies.token || !!user) {
      // For a transitionary time after launching the new domain token usage, we'll need to clear the old cookie token
      // as well, since it's a more specific cookie (sub-domain) that is not cleared by the higher level domain cookie
      destroyCookie(undefined, CookieKeys.TOKEN, {
        path: '/',
      })
      destroyCookie(undefined, CookieKeys.TOKEN, {
        // We only use the domain for cookies in production so that sessions can be shared across consumer & admin
        ...(process.env['NEXT_PUBLIC_TOKEN_COOKIE_DOMAIN']
          ? { domain: process.env['NEXT_PUBLIC_TOKEN_COOKIE_DOMAIN'] }
          : {}),
        path: '/',
      })
      setUser(undefined)
      setUserCookies({
        ...userCookies,
        token: '',
      })
    }
  }, [user, userCookies])

  /** Set User data from the backend into state */
  useEffect(() => {
    if (userData.isSuccess || userData.isError) {
      setUser(userData.user)
      setUserReady(true)
    }
  }, [userData])

  /** Sync values from cookies & localstorage */
  useEffect(() => {
    setCREATermsAccepted(getLocalItem('CREATermsAccepted') as boolean)

    if (!getLocalItem('uuid')) {
      setLocalItem('uuid', uuidv4())
    }

    setUUID(getLocalItem('uuid') as string)
  }, [])

  /** Method exposed to update the CREA terms acceptance */
  const updateCREATermsAccepted = useCallback((isSent: boolean) => {
    setLocalItem('CREATermsAccepted', isSent)
    setCREATermsAccepted(isSent)
  }, [])

  /** Method exposed to update whether or not the user is seeing a full set of summaries */
  const toggleCondensedSummary = useCallback(
    (isCondensed: boolean) => {
      setUserCookies({
        ...userCookies,
        condensedSummary: isCondensed,
      })

      !isCondensed
        ? destroyCookie(undefined, CookieKeys.CONDENSED_SUMMARY, {
            path: '/',
          })
        : setCookie(undefined, CookieKeys.CONDENSED_SUMMARY, 'enabled', {
            maxAge: TOKEN_EXPIRY_TIME,
            path: '/',
          })
    },
    [userCookies]
  )

  /** Method exposed to update whether or not the user is seeing a full set of summaries */
  const setPreviewViewType = useCallback(
    (viewType?: PreviewViewsType) => {
      setUserCookies({
        ...userCookies,
        previewView: viewType,
      })

      !viewType
        ? destroyCookie(undefined, CookieKeys.PREVIEW_VIEW, {
            path: '/',
          })
        : setCookie(undefined, CookieKeys.PREVIEW_VIEW, viewType, {
            maxAge: TOKEN_EXPIRY_TIME,
            path: '/',
          })
    },
    [userCookies]
  )

  const values = useMemo(() => {
    const { token, condensedSummary, previewView } = userCookies
    // We declare the context here with the type before returning it, so we always ensure that the signatures match
    const context: UserContextType = {
      hasToken: !!token,
      user,
      isLoading: userData.isLoading,
      updateUser,
      clearUser,
      // NOTE: for SSR, check `hasToken` value instead, otherwise an extra trip to GetUser will be required
      // In the event of an expired or invalid token, 401 will be handled by redirects
      isLoggedIn: Boolean(user && userReady),
      CREATermsAccepted,
      updateCREATermsAccepted,
      uuid,
      userReady,
      condensedSummary,
      toggleCondensedSummary,
      previewView,
      setPreviewViewType,
      updateUserCookieToken,
      hasAdminAccess: TEAM_MEMBER_USER_TYPES.includes(user?.userType as UserType),
      twilioClient,
    }

    return context
  }, [
    userCookies,
    user,
    userData.isLoading,
    updateUser,
    clearUser,
    userReady,
    CREATermsAccepted,
    updateCREATermsAccepted,
    uuid,
    toggleCondensedSummary,
    setPreviewViewType,
    updateUserCookieToken,
    twilioClient,
  ])

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>
}

export default UserInfoProvider
