import { StorageKey } from './constants'

export function getLocalItem(key: StorageKey, fallback?: unknown): unknown {
  try {
    const rawValue = localStorage.getItem(key)
    const value = rawValue ? JSON.parse(rawValue) : null
    if (value == null) {
      return fallback == null ? null : fallback
    }

    return value
  } catch (e) {
    return fallback
  }
}

export function setLocalItem(key: StorageKey, value: unknown) {
  try {
    localStorage.setItem(key, JSON.stringify(value || ''))
  } catch (e) {
    // Just ignore the error as can't do much to recover.
  }
}

export function removeLocalItem(key: StorageKey) {
  try {
    localStorage.removeItem(key)
  } catch (e) {
    // Just ignore the error as can't do much to recover.
  }
}
