import { ListingOpportunityStats } from '@unreserved-frontend-v2/api/generated/graphql/types'
import { HAVE_WINDOW } from '@unreserved-frontend-v2/utils/window'

export const API_ENDPOINT = (
  HAVE_WINDOW ? process.env['NEXT_PUBLIC_API'] : process.env['NEXT_PUBLIC_API_SSR']
) as string

export const THE_PUMP = { lat: 45.415, lng: -75.688 }
export const OLD_SPAGHETTI_FACTORY = { lat: 43.647, lng: -79.374 }

export const QUERY_ERROR_MESSAGES = {
  UserNotFound: 'User not found',
}

export const NEARBY_LISTINGS_LIMIT_MOBILE = 3
export const NEARBY_LISTINGS_LIMIT_DESKTOP = 8

export const NO_OPPORTUNITY_STATS: ListingOpportunityStats = { inProgress: 0, lost: 0, toQualify: 0, transacted: 0 }
