import { UseQueryOptions } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import { useMemo } from 'react'

import { MutationErrorResponse } from '@unreserved-frontend-v2/utils/api/mutation-error-response'

import { API_ENDPOINT } from '../constants'
import { GetUserQuery, useGetUserQuery } from '../generated/graphql/get-user.graphql'
import { Query } from '../generated/graphql/types'

// ------------------------------------------------------------------------------------------------------------
//
// NOTE:  Our hooks make use of useMemo() because they all return an object and if we return a new object
//        every time (even when the data doesn't change) any useEffects() that are dependent on this data
//        will re-run and potentially cause infinite render loops.
//
// ------------------------------------------------------------------------------------------------------------

export function useGetUser(options: UseQueryOptions<GetUserQuery, MutationErrorResponse, Query> = {}) {
  const { data, refetch, isError, error, isSuccess, isLoading } = useGetUserQuery<Query, MutationErrorResponse>(
    new GraphQLClient(API_ENDPOINT),
    undefined,
    { ...options }
  )

  return useMemo(() => {
    return { user: data?.user, refetch, error, isError, isSuccess, isLoading }
  }, [data?.user, refetch, error, isError, isSuccess, isLoading])
}
